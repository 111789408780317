<template>
  <div class="Member bg-white">
    <Row class="px-2">
      <i-col span="10">
        <Form :model="activityInfo" :label-width="70">
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="活动名称" label-position="top">
                <Input v-model="activityInfo.activityName" size="large" />
              </FormItem>
            </i-col>
            <i-col span="24">
              <FormItem label="活动图片" label-position="top">
                <Upload
                  type="drag"
                  :format="['jpg','jpeg','png']"
                  :on-success="handleSuccess"
                  :max-size="2048"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize"
                  action="https://swapi.hxypsy.cn/api/Activity/UploadImage"
                >
                  <div style="padding: 20px 0">
                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                    <p>点击或拖动文件到这里上传</p>
                  </div>
                </Upload>
              </FormItem>
            </i-col>
            <i-col span="24">
              <FormItem label="活动内容">
                <quill-editor
                  ref="introEditor"
                  :options="introOption"
                  v-model="activityInfo.intro"
                >></quill-editor>
              </FormItem>
              <div style="margin-top:4rem;float:left;margin-left:70px;">
                <Button type="primary" @click="createActivity">创建</Button>
              </div>
            </i-col>
          </Row>
        </Form>
      </i-col>
      <i-col span="14">
        <Scroll :height="theight">
          <i-col span="23">
            <Form :label-width="100" style="padding-left:10px">
              <Row :gutter="32" ref="formDynamic" :rule="rules" :model="formDynamic">
                <i-col span="24">
                  <div v-for="(item, index) in formDynamic.activity" :key="index">
                    <Row :gutter="32">
                      <i-col span="22">
                        <FormItem
                          :label="`第${index + 1}阶段`"
                          label-position="left"
                          :prop="`activity`"
                        >
                          <i-col span="24">
                            <FormItem label="阶段活动名称" label-position="top">
                              <Input
                                v-model="item.childActivityName"
                                size="large"
                                style="width:95%;float:left"
                                placeholder="请输入阶段活动名称"
                              />
                            </FormItem>
                          </i-col>
                          <i-col span="24">
                            <FormItem label="阶段活动地点" label-position="top">
                              <Input
                                v-model="item.childActivityAddress"
                                size="large"
                                style="width:95%;float:left"
                                placeholder="请输入阶段活动举办地点"
                              />
                            </FormItem>
                          </i-col>
                          <i-col span="12">
                            <FormItem label="阶段人数限制" label-position="top">
                              <Input
                                v-model="item.limitPeople"
                                size="large"
                                style="width:90%;float:left"
                                placeholder="请输入阶段活动人数限制"
                              />
                            </FormItem>
                          </i-col>
                          <i-col span="12">
                            <FormItem label="阶段报名费用" label-position="top">
                              <Input
                                v-model="item.applyPrice"
                                size="large"
                                style="width:90%;float:left"
                                placeholder="请输入阶段活动报名费用"
                              />
                            </FormItem>
                          </i-col>
                          <i-col span="12">
                            <FormItem label="阶段活动时间" label-position="top">
                              <DatePicker
                                v-model="item.activityTime"
                                type="datetimerange"
                                placement="bottom-start"
                                size="large"
                                style="width:90%;float:left"
                                placeholder="请选择阶段活动时间"
                                :on-ok="checkTime_(item)"
                              ></DatePicker>
                            </FormItem>
                          </i-col>
                          <i-col span="12">
                            <FormItem label="阶段报名截止" label-position="top">
                              <DatePicker
                                v-model="item.deadline"
                                type="datetime"
                                placement="bottom-end"
                                size="large"
                                style="width:90%;float:left"
                                placeholder="请选择阶段活动截止时间"
                              ></DatePicker>
                            </FormItem>
                          </i-col>
                          <i-col span="24">
                            <FormItem label="阶段活动描述" label-position="top">
                              <Input
                                v-model="item.childActivityDesc"
                                maxlength="150"
                                :rows="3"
                                show-word-limit
                                type="textarea"
                                style="width:95%;float:left"
                                placeholder="输入描述..."
                              />
                            </FormItem>
                          </i-col>
                        </FormItem>
                      </i-col>
                      <i-col span="2" style="margin-top:35px;">
                        <Poptip
                          confirm
                          title="确定删除此阶段活动?"
                          @on-ok="removeActivity(index)"
                          placement="left-start"
                        >
                          <Button type="dashed" shape="circle" icon="md-power"></Button>
                        </Poptip>
                      </i-col>
                    </Row>
                  </div>
                  <FormItem style="margin-top:5px;">
                    <Row>
                      <i-col span="6">
                        <Button
                          type="dashed"
                          size="large"
                          long
                          @click="addPhase"
                          icon="md-add"
                        >添加活动阶段</Button>
                      </i-col>
                    </Row>
                  </FormItem>
                </i-col>
              </Row>
            </Form>
          </i-col>
        </Scroll>
      </i-col>
    </Row>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import Quill from "quill"; //引入编辑器

export default {
  name: "ActivityDesign",
  components: {
    quillEditor,
  },
  data() {
    return {
      car_index: 0,
      recordTotal: 0,
      rules: {},
      theight: window.innerHeight - 75,
      activityInfo: {
        intro: "",
      },
      file: null,
      formDynamic: {
        activity: [],
      },
      introOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ font: [] }], //字体
          ],
        },
        theme: "snow",
        placeholder: "请输入活动内容...",
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.introEditor.quill;
    },
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 75),
      false
    );
  },
  methods: {
    changeSize(e) {
      this.pageSize = e;
    },
    changePage(e) {
      this.pageNum = e;
    },
    checkTime_(item) {
      if (item.activityTime == undefined) {
        return;
      } else {
        item.startTime = item.activityTime[0];
        item.endTime = item.activityTime[1];
      }
    },
    removeActivity(index) {
      //删除题目
      this.formDynamic.activity.splice(index, 1);
      this.recordTotal = this.recordTotal - 1;
    },
    addPhase() {
      // 新增题目
      this.formDynamic.activity.push({});
      this.recordTotal = this.recordTotal + 1;
    },
    handleSuccess(res, file) {
      this.activityInfo.photos = res;
    },
    handleFormatError(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 格式错误, 请选择 jpg 或者 png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 过大, 请选择不超过2M的文件.",
      });
    },
    createActivity() {
      this.activityInfo.ActivityContent = this.activityInfo.intro;
      this.activityInfo.children_ = JSON.stringify(this.formDynamic.activity);
      let data = this.activityInfo;
      console.log(data);
      this.$post("Activity/AddActivity", data).then((res) => {
        if (res.data.code) {
          this.$Message.success({
            content: "创建成功",
            duration: 3,
          });
          this.$router.back(-1);
        } else {
          this.$Message.success({
            content: "创建失败，请重试",
            duration: 3,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
@import "../assets/quill.css";
.quill-editor {
  height: 25rem;
  border-radius: 0.25rem;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc;
}
</style>